"use client";

import { useConsent } from "@packages/consent";
import {
  getCookieAjsAnonymousId,
  getDeviceIsMobile,
  getDeviceType,
  getFullName,
  useAccessibility,
  useRequestMe,
} from "@packages/sdk";
import { useSearchParams } from "next/navigation";
import type { ComponentProps } from "react";
import { Suspense, useEffect, useState } from "react";

import { analytics, analyticsContext } from "../lib";
import type { AnalyticsEvent } from "../types";

export type AnalyticsProviderProps = Omit<
  ComponentProps<typeof analyticsContext.Provider>,
  "value"
>;

const Provider = ({ children, ...props }: AnalyticsProviderProps) => {
  const [isIdentified, setIsIdentified] = useState(false);

  const searchParams = useSearchParams();

  const { hlwFontStyle, hlwLetterSpacing, hlwWordSpacing, preferredTheme } =
    useAccessibility();
  const { ajsAnonymousId, consent, isConsenting, setAjsAnonymousId } =
    useConsent();
  const { query: queryMe } = useRequestMe();

  const logout = () => analytics?.reset();
  const track = ({ event, properties }: AnalyticsEvent) => {
    const isWindowExists = typeof window !== "undefined";

    return analytics.track(event, {
      device_type: getDeviceType(),
      font_style: hlwFontStyle,
      letter_spacing: hlwLetterSpacing ? "expanded" : "default",
      mobile: getDeviceIsMobile(),
      platform: "web",
      referrer: searchParams.get("referrer"),
      screen_height: isWindowExists ? window?.innerHeight : 0,
      /** TODO: define getScreenName() */
      screen_name: isWindowExists ? window?.location.pathname : "",
      screen_width: isWindowExists ? window?.innerWidth : 0,
      slug: `${window.location.pathname}${window.location.search}${window.location.hash}`,
      theme: preferredTheme,
      utm_campaign: searchParams.get("utmCampaign"),
      utm_medium: searchParams.get("utmMedium"),
      utm_source: searchParams.get("utmSource"),
      word_spacing: hlwWordSpacing ? "expanded" : "default",
      ...properties,
    }) as unknown as Promise<void>;
  };

  /** If the user is consenting, load analytics */
  useEffect(() => {
    if (isConsenting) {
      analytics.load(
        {
          writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        },
        {
          integrations: {
            "Segment.io": {
              apiHost: process.env.NEXT_PUBLIC_SEGMENT_API_HOST,
              protocol: "https",
            },
          },
        },
      );
      analytics.identify().then(() => {
        /**
         * Update context ajs_anonymoud_id cookie if not in-sync
         * To keep LD (guest context) id in sync with Segment id
         */
        const cookieAjsAnonymousId = getCookieAjsAnonymousId();
        if (ajsAnonymousId !== cookieAjsAnonymousId)
          setAjsAnonymousId(cookieAjsAnonymousId);
      });
    }
  }, [isConsenting]);

  /**
   * If user authenticates, sync pre-auth and post-auth analytics
   * Segment analytics.identify() syncs events
   */
  const isIdentifiable = isConsenting && !isIdentified && queryMe.data?.id;
  useEffect(() => {
    if (isIdentifiable) {
      analytics.identify(queryMe.data.id.toString(), {
        consent,
        email: queryMe.data?.email,
        locale: queryMe.data?.language,
        name: getFullName({
          firstName: queryMe.data?.name,
          lastName: queryMe.data?.last_name,
        }),
        phoneNumber: queryMe.data?.phone,
      });
      setIsIdentified(true);
    }
  }, [isIdentifiable]);

  return (
    <analyticsContext.Provider
      value={{
        analytics,
        logout,
        track,
      }}
      {...props}
    >
      {children}
    </analyticsContext.Provider>
  );
};

export const AnalyticsProvider = ({
  children,
  ...props
}: AnalyticsProviderProps) => (
  <Suspense>
    <Provider {...props}>{children}</Provider>
  </Suspense>
);
